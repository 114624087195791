


















import {Component} from 'vue-property-decorator';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import SelfCareTable from '@/components/tables/SelfCaresTable.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import {readSelfCares} from '@/store/crud/getters';
import {dispatchDeleteSelfCare, dispatchGetSelfCares} from '@/store/crud/actions';

@Component({components: {ConfirmationModal, SelfCareTable}})
export default class SelfCares extends AppComponent {
  get selfCares() {
    const selfCares = readSelfCares(this.$store);
    if (selfCares) {
      return selfCares;
    } else {
      return [];
    }
  }
  public fetchingSelfCareData = false;
  /**
   * created hook, it only calls async fetch data
   */
  public created() {
    this.fetchData();
  }

  /**
   * fetches needed data from the store/ server and displays
   * loading spinner
   */
  public async fetchData() {
    this.fetchingSelfCareData = true;
    await this.$nextTick();
    await dispatchGetSelfCares(this.$store);
    this.fetchingSelfCareData = false;
  }

  /**
   * Delete a selfCare and refresh the screen
   */
  public async deleteSelfCare(selfCareId: string) {
    await dispatchDeleteSelfCare(this.$store, {selfCareId});
    this.fetchData();
  }
}
